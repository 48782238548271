import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import "../styles.scss";


const NotFoundPage = () => {
  return (
    <>
    <Header />
    <div className="container">
      <div className="row" style={{textAlign: "center", margin: "100px 0px 100px 0px"}}>
        <p style={{fontSize: "48px"}}>#erro404</p>
        <h1>Opa, não achamos o que você queria. :-(</h1>
        <h4>Que tal tentar novamente?</h4>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default NotFoundPage
